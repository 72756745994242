<template>
  <!-- <div class="sidebar__link-wrapper">
    <router-link to="/tutor-groups" class="sidebar__link">
      <img src="@/assets/img/group-icon-white.png" alt="" />
      <p>{{ $t('Sidebar.groups') }}</p>
    </router-link>
  </div> -->
</template>

<script>
export default {
  setup() {
    const isTest = process.env.VUE_APP_MODAL_LOGIN;
    return {
      isTest,
    };
  },
};
</script>

<style></style>
