<template>
  <div class="signin h-screen flex justify-center items-center bg-[#16233c]">
    <div class="bg-white flex items-center gap-4 rounded-2xl p-3">
      <div class="w-[350px]" v-loading="loading">
        <img
          src="@/assets/img/mars-logo.png"
          alt="logo"
          class="mx-auto w-[100px]"
        />
        <SectionTitle
          :title="$t('welcome-back')"
          class="capitalize my-5 text-[30px] text-center font-bold"
        />
        <form @submit.prevent="signIn" class="w-full p-5">
          <el-select
            v-model="selectedRole"
            class="my-5 w-full"
            placeholder="Select role"
            size="large"
            v-if="testLogin"
            @change="autofill()"
          >
            <el-option
              v-for="item in roles"
              :key="item"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <label for="username" class="font-medium"
            ><span class="text-red-500">*</span>{{ $t("phone-number") }}</label
          >
          <input
            id="username"
            type="text"
            v-model="phoneNumber"
            :placeholder="$t('phone-number')"
            v-mask="'+998 (##) ###-##-##'"
            class="w-full p-2 rounded-md border border-gray-300 border-solid"
            name="phoneNumber"
            autofocus
          />
          <div class="mt-5">
            <label for="password"
              ><span class="text-red-500">*</span>{{ $t("password") }}</label
            >
          </div>

          <div class="password-box relative">
            <input
              class="w-full p-2 rounded-md border border-gray-300 border-solid"
              id="password"
              :type="showPassword ? 'text' : 'password'"
              v-model="password"
              placeholder="Password"
              name="password"
            />

            <button
              type="button"
              @click="togglePasswordVisibility"
              class="absolute right-2 top-1/2 transform -translate-y-1/2"
            >
              <i
                :class="
                  showPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'
                "
              ></i>
            </button>
          </div>

          <button
            class="signin-btn w-full bg-orangeThin text-white p-2 rounded-md cursor-pointer mt-10 disabled:opacity-50"
            :disabled="phoneNumber.length !== 19 || !password.length"
            type="submit"
          >
            <span>{{ $t("sign-in") }}</span>
          </button>
        </form>
      </div>
      <img
        class="form-img hidden md:block"
        src="@/assets/img/austronaut.png"
        alt=""
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { decryptText } from "@/utils/cryptFunc";
import SectionTitle from "@/components/SectionTitle.vue";
import { ElMessage } from "element-plus";
const store = useStore();
const router = useRouter();

const showPassword = ref(false);
const user = ref(decryptText(localStorage.getItem("role")));

const phoneNumber = ref("+998");
const password = ref("");
const selectedRole = ref("");
const testLogin = ref(process.env.VUE_APP_MODAL_LOGIN || false);
const roles = store.state.roles.roles;
const loading = ref(false);

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};

const signIn = async () => {
  const phone = phoneNumber.value.replace(/[() \-]/g, "");
  loading.value = true;
  try {
    await store.dispatch("auths/login", {
      user: {
        phone: phone.trim(),
        password: password.value.trim(),
      },
    });
  } catch (error) {
    ElMessage.error("Telefon raqam yoki parol notog'ri yozilgan");
  }
  loading.value = false;
};

const autofill = () => {
  phoneNumber.value = roles[selectedRole.value].login;
  password.value = roles[selectedRole.value].pass;
};

onBeforeMount(() => {
  if (localStorage.getItem("access_token")) {
    router.push({ name: `${user.value}-main` });
  }
});
</script>
