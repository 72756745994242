import { createApp, Vue } from "vue";
import router from "./router/router";
import { getDevs } from "./developers";
import App from "./App.vue";
import ElementPlus from "element-plus";
import { i18n } from "@/i18n";
import VCalendar from "v-calendar";
import VueAwesomeSwiper from "vue-awesome-swiper";
import TheMask from "vue-the-mask";

// import VueNativeSock from "vue-native-websocket-vue3";

import "v-calendar/style.css";
import "element-plus/dist/index.css";
import "@/assets/style/index.css";
import "@/assets/style/header/header.css";
import "@/assets/style/sidebar/sidebar.css";
import "@/assets/style/main-page/top-rating.css";
import "@/assets/style/main-page/groups.css";
import "@/assets/style/admin-main/admin-main.css";
import "@/assets/style/not-found/not-found.css";
import "@/assets/style/instructor/instructor.css";
import "@/assets/style/pagination/pagination.css";
import "@/assets/style/group-lessons/group-lessons.css";
import "@/assets/style/teacher-stat/teacher-stat.css";
import "@/assets/style/teachers-audit/teachers-audit.css";
import "@/assets/style/students-attendance/students-attendance.css";
import "@/assets/style/search-bar/search-bar.css";
import "@/assets/style/group-examine-date/group-examine-date.css";
import ru from "element-plus/dist/locale/ru.mjs";
import uz from "element-plus/dist/locale/uz-uz.mjs";
import en from "element-plus/dist/locale/en.mjs";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "@/assets/style/sales/sales.css";
import store from "./store";

const lang = localStorage.getItem("lang") || "uz";

const locales = {
  ru,
  uz,
  en,
};

createApp(App)
  .use(router)
  .use(i18n)
  .use(ElementPlus, {
    locale: locales[lang],
  })
  .use(store)
  .use(getDevs)
  .use(VCalendar, {})
  .use(VueAwesomeSwiper)
  .use(TheMask)
  .mount("#app");
