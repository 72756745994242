import api from "@/apiConfig/api";
import apiV2 from "@/apiConfig/api-v2";

export default {
  state() {
    return {
      drag_student_id: null,
      grLeads: {},
      colGrps: {},
      draggedGr: {},
      droppedGr: {},
      draggedGrPage: null,
      droppedGrPage: null,
      isShowCols: {},
      draggedStd: null,
      searchedGroups: [],
    };
  },
  mutations: {
    SETTEACHERINFO(state, payload) {
      state.teacher_name = payload.teacher_name;
      state.time = payload.time;
      state.group = payload.group;
      state.teacher_id = payload.teacher_id;
    },
    SET_SEARCHED_GROUPS(state, groups) {
      state.searchedGroups = groups || [];
    },
  },
  actions: {
    setGrPage(ctx, { type, page }) {
      if (type == "dragged") ctx.state.draggedGrPage = page;
      else if (type === "dropped") ctx.state.droppedGrPage = page;
    },
    setDragStudentId(context, student_id) {
      context.state.drag_student_id = student_id;
    },
    setDraggedStd(context, std) {
      context.state.draggedStd = std;
    },
    async getGrLeads(ctx, groupId) {
      ctx.state.grLeads[groupId] = await api
        .get(`/groups/${groupId}`, {
          params: {
            group_id: groupId,
          },
        })
        .then((res) => res.data.students)
        .catch(() => []);
    },

    async getColGrps(
      ctx,
      {
        course_id,
        branch_id,
        sorting = false,
        page = 1,
        name = null,
        days = "all",
      }
    ) {
      if (!ctx.state.colGrps[course_id]) ctx.state.colGrps[course_id] = {};
      if (!ctx.state.colGrps[course_id][page])
        ctx.state.colGrps[course_id][page] = [];

      await apiV2
        .get("/groups/search", {
          params: {
            course_id,
            branch_id,
            sorting,
            page,
            name,
            days,
          },
        })
        .then((res) => {
          ctx.state.colGrps[course_id][page] = res.data;
        })
        .catch(() => {
          ctx.state.colGrps[course_id][page] = [];
        });
    },

    async setGr(ctx, { type, gr }) {
      if (type === "dragged") {
        ctx.state.draggedGr = gr;
      } else if (type === "dropped") {
        ctx.state.droppedGr = gr;
      }
    },

    async searchGroup({ commit }, { branch_id, name }) {
      try {
        const res = await apiV2.get("/groups/search", {
          params: { branch_id, name },
        });
        const groups = res.data;
        commit("SET_SEARCHED_GROUPS", groups);
      } catch (error) {
        console.error("Error fetching groups:", error);
        commit("SET_SEARCHED_GROUPS", []);
      }
    },
  },
};
