export default {
  state() {
    return {
      roles: {
        teacherPro: {
          login: process.env.VUE_APP_TEACHER_PRO_LOGIN,
          pass: process.env.VUE_APP_TEACHER_PRO_PASSWORD,
          label: "Pro teacher",
          value: "teacherPro",
        },

        academymanager: {
          login: process.env.VUE_APP_ACADEMY_MANAGER_LOGIN,
          pass: process.env.VUE_APP_ACADEMY_MANAGER_PASSWORD,
          label: "Academy manager",
          value: "academymanager",
        },
        admin: {
          login: process.env.VUE_APP_ADMIN_LOGIN,
          pass: process.env.VUE_APP_ADMIN_PASSWORD,
          label: "Admin",
          value: "admin",
        },
        instructor: {
          login: process.env.VUE_APP_INSTRUCTOR_LOGIN,
          pass: process.env.VUE_APP_INSTRUCTOR_PASSWORD,
          label: "Instructor",
          value: "instructor",
        },

        callCenter: {
          login: process.env.VUE_APP_CALL_CENTER_LOGIN,
          pass: process.env.VUE_APP_CALL_CENTER_PASSWORD,
          label: "Call center",
          value: "callCenter",
        },
        examiner: {
          login: process.env.VUE_APP_EXAMINER_LOGIN,
          pass: process.env.VUE_APP_EXAMINER_PASSWORD,
          label: "Examiner",
          value: "examiner",
        },
        finance: {
          login: process.env.VUE_APP_FINANCE_LOGIN,
          pass: process.env.VUE_APP_FINANCE_PASSWORD,
          label: "Finance",
          value: "finance",
        },

        marketing: {
          login: process.env.VUE_APP_MARKETING_LOGIN,
          pass: process.env.VUE_APP_MARKETING_PASSWORD,
          label: "Marketing",
          value: "marketing",
        },
        axo: {
          login: process.env.VUE_APP_AXO_LOGIN,
          pass: process.env.VUE_APP_AXO_PASSWORD,
          label: "Axo",
          value: "axo",
        },
        hunter: {
          login: process.env.VUE_APP_HUNTER_LOGIN,
          pass: process.env.VUE_APP_HUNTER_PASSWORD,
          label: "Hunter",
          value: "hunter",
        },
        tutor: {
          login: process.env.VUE_APP_TUTOR_LOGIN,
          pass: process.env.VUE_APP_TUTOR_PASSWORD,
          label: "Tutor",
          value: "tutor",
        },
        moderator: {
          login: process.env.VUE_APP_MODERATOR_LOGIN,
          pass: process.env.VUE_APP_MODERATOR_PASSWORD,
          label: "Blog Moderator",
          value: "moderator",
        },
      },
    };
  },
};
