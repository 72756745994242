<template>
  <div class="sidebar__link-wrapper">
    <router-link to="/academy-teacher-list" class="sidebar__link">
      <img src="@/assets/img/mentors-academy.png" alt="" />
      <p>{{ $t("Sidebar.mentors-tutors") }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/teacher-statistics" class="sidebar__link">
      <img src="@/assets/img/academy-statistics.png" alt="" />
      <p>{{ $t("Sidebar.teachers-stats") }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/teacher-audit" class="sidebar__link">
      <img src="@/assets/img/audit-icon.png" alt="" />
      <p>{{ $t("Sidebar.qa-audit") }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/teacher-audit-statistics" class="sidebar__link">
      <img src="@/assets/img/academy-statistics.png" alt="" />
      <p>{{ $t("Sidebar.academy-statistics") }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/teachers-grading" class="sidebar__link">
      <img src="@/assets/img/academy-statistics.png" alt="" />
      <p>{{ $t("Sidebar.teachers-grading") }}</p>
    </router-link>
  </div>

  <div class="sidebar__link-wrapper">
    <router-link to="/teachers-lessons-schedule" class="sidebar__link">
      <img src="@/assets/img/schedule-white.png" alt="" />
      <p>{{ $t("Sidebar.schedule") }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/generate-certificate" class="sidebar__link">
      <img src="@/assets/img/certificate-sidebar-icon.png" alt="" />
      <p>{{ $t("Sidebar.certificate") }}</p>
    </router-link>
  </div>
</template>
