<template>
  <div v-if="homework">
    <div class="mt-5 border border-solid border-[#F7F7F7] p-2 rounded">
      <p class="text-[#004466] font-bold text-sm">Vazifa</p>
      <div
        class="border-0 border-solid border-t border-b flex gap-5 border-[#F7F7F7] mt-2"
      >
        <div class="p-2 w-1/2">
          <p class="text-xl font-bold text-[#FC6736]">Tavsif</p>
          <p
            class="text-sm text-[#B3B3B3]"
            v-html="marked(homework.description[lang] || '-')"
          ></p>
        </div>
        <div class="p-2 w-1/2">
          <p class="text-xl font-bold text-[#FC6736]">Talablar</p>
          <ul>
            <li
              v-for="(req, i) in homework.requirements"
              :key="req"
              class="text-sm text-[#B3B3B3]"
            >
              {{ i + 1 }}. {{ req[lang] }} - {{ req.coins }} coin
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      class="mt-2 border border-solid border-[#F7F7F7] p-2 rounded"
      v-if="homework.images?.length"
    >
      <p class="text-xl font-bold text-[#FC6736]">Rasmlar</p>
      <div class="flex gap-2 flex-wrap">
        <el-image
          v-for="(image, k) in homework.images"
          :key="k"
          style="width: 250px; height: 150px; object-fit: contain"
          :src="medialink + image"
          :zoom-rate="1.2"
          :preview-src-list="allImages()"
          :initial-index="k"
          fit="cover"
        />
      </div>
    </div>

    <div
      v-if="homework.files?.length"
      class="mt-2 border border-solid border-[#F7F7F7] p-2 rounded"
    >
      <p class="text-xl font-bold text-[#FC6736]">Materiallar</p>
      <div
        class="flex gap-1 items-center"
        v-for="file in homework.files"
        :key="file"
      >
        <i class="fa-solid fa-file text-yellow-500"></i>
        <a
          class="text-[#35BCFF] underline"
          target="_blank"
          :href="medialink + file"
          download
        >
          {{
            file.lastIndexOf("/") > -1
              ? file.slice(file.lastIndexOf("/") + 1)
              : file
          }}
        </a>
      </div>
    </div>
  </div>
  <div v-else class="mt-5">
    <p class="text-xl font-bold text-[#b3b3b3]">Homework not found</p>
  </div>
</template>

<script setup>
import { medialink } from "@/apiConfig/medialink";
import { marked } from "marked";
const props = defineProps(["homework"]);
let lang = localStorage.getItem("lang") || "uz";
marked.setOptions({
  headerIds: false,
  mangle: false,
});

if (lang === "en") lang = "uz";

const allImages = () => {
  let images = [];
  props.homework.images.forEach((image) => {
    images.push(medialink + image);
  });
  return images;
};
</script>
