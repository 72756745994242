<template>
  <div class="mt-5 methodology">
    <el-collapse>
      <el-collapse-item title="Methodology" name="1">
        <div
          v-if="methodology?.length"
          v-for="(item, i) in methodology"
          :key="i"
          class="border border-solid border-[#F7F7F7] p-2 mb-2 rounded"
        >
          <div class="flex items-center justify-between">
            <p class="text-[#FC6736] font-bold text-xl">
              {{ lang === "uz" ? item?.title_uz : item?.title_ru }}
            </p>
            <p class="text-xl font-bold text-[#004466]">
              {{
                DateTime.fromISO(item.to_minute)
                  .diff(DateTime.fromISO(item.from_minute), "minutes")
                  .toObject().minutes
              }}
              min
            </p>
          </div>
          <p v-if="lang === 'uz'" v-html="item?.description_uz"></p>
          <p v-html="item?.description_ru"></p>
        </div>
        <div v-else>
          <p class="text-xl font-bold text-[#b3b3b3]">Methodology not found</p>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script setup>
import { DateTime } from "luxon";

const props = defineProps(["methodology"]);

let lang = localStorage.getItem("lang") || "uz";
if (lang === "en") lang = "uz";
</script>
<style lang="scss">
.methodology {
  .el-collapse-item__header {
    color: #004466;
    font-weight: 600;
  }
}
</style>
