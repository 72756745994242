<template>
  <component :is="layout + '-layout'" v-if="layout" />

  <ScheduleCollapse v-if="userRole === 'admin' || userRole === 'hunter'" />
</template>

<script>
import mainLayout from "./layouts/mainLayout.vue";
import authLayout from "./layouts/authLayout.vue";
import { computed, onBeforeMount, watch } from "vue";
import { useRoute } from "vue-router";
import { changeTitle } from "./utils/changeTitle";
import ScheduleCollapse from "@/components/ScheduleCollapse.vue";
import { decryptText } from "@/utils/cryptFunc";
export default {
  name: "App",

  setup() {
    const route = useRoute();
    const userRole = decryptText(localStorage.getItem("role"));

    onBeforeMount(() => {
      changeTitle(route.path);
    });
    watch(
      () => route.name,
      () => {
        changeTitle(route.name);
      }
    );
    return {
      layout: computed(() => route.meta.layout),
      role: computed(() => route.meta.role),
      userRole,
    };
  },
  components: {
    mainLayout,
    authLayout,
    ScheduleCollapse,
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  margin: 0;
  padding: 0;
}

.container {
  width: 100vw;
  margin: 0 auto;
}
</style>
